<template>
  <div style="background: white">
    <Loading :loadingCounter="loadingCounter" />
    <v-sheet class="overflow-hidden">
      <NavBar tittle="label.lbl_leavingAchieveAdd" />
      <!-- （画面名）出庫実績登録-->
      <v-container fluid>
        <!-- 検索項目-->
        <v-row class="search-row">
          <div class="search-autocomplete first-search-item">
            <div v-if="suppliersSelected.length > 20">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div style="height: 20px" v-bind="attrs" v-on="on">
                    <v-text-field
                      dense
                      readonly
                      v-model="suppliersSelected"
                      :label="$t('label.lbl_supplier')"
                      class="txt-single"
                      persistent-hint
                      margin-bottom="0"
                      pa-3
                    ></v-text-field>
                  </div>
                </template>
                <span>{{ suppliersSelected }} </span>
              </v-tooltip>
            </div>
            <div v-else>
              <div>
                <v-text-field
                  dense
                  readonly
                  v-model="suppliersSelected"
                  :label="$t('label.lbl_supplier')"
                  class="txt-single"
                  persistent-hint
                  margin-bottom="0"
                  pa-3
                ></v-text-field>
              </div>
            </div>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- 出荷先-->
            <v-text-field
              dense
              readonly
              v-model="shippingSelected"
              :label="$t('label.lbl_shippingTo')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="date-style">
            <!-- 出荷日-->
            <v-text-field
              readonly
              dense
              v-model="shippingScheduleDate"
              :label="$t('label.lbl_scheduleDate')"
              class="txt-single"
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- ピッキング-->
            <v-text-field
              readonly
              dense
              v-model="pickingNm"
              :label="$t('label.lbl_picking')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>

          <!-- 空白 -->
          <span class="item-spacer">&nbsp;</span>

          <div class="search-autocomplete">
            <!-- ピッキングNo-->
            <v-text-field
              readonly
              dense
              v-model="pickingNo"
              :label="$t('label.lbl_pickingNo')"
              class="txt-single"
              persistent-hint
            ></v-text-field>
          </div>
        </v-row>

        <v-row>
          <div class="search-row-exeBtn">
            <div class="btn-area" style="float: left">
              <!-- 戻るボタン-->
              <v-btn class="other-btn" v-on:click="backbtn">
                {{ $t("btn.btn_back") }}
              </v-btn>
            </div>
            <span class="item-spacer" style="float: left">&nbsp;</span>

            <div class="btn-area">
              <!-- 登録ボタン-->
              <v-btn class="post-btn" value="1" v-on:click="rcvAchAdd">
                {{ $t("btn.btn_insert") }}
              </v-btn>

              <!-- メッセージダイアログ-->
              <ConfirmDialog
                :isShow.sync="ConfirmDialog.isOpen"
                :message="ConfirmDialog.message"
                :okAction="deleteStock"
                :redMessage="ConfirmDialog.redMessage"
                :screenFlag="ConfirmDialog.screenFlag"
                :changeFlag="ConfirmDialog.changeFlag"
              />
            </div>
          </div>
        </v-row>
      </v-container>
      <!-- データテーブル（項目） -->
      <v-form ref="editedList" lazy-validation>
        <v-container fluid>
          <v-data-table
            id="listData"
            fixed-header
            :headers="headerItems"
            :items="inputList"
            disable-filtering
            disable-sort
            disable-pagination
            :hide-default-footer="true"
            height="620px"
          >
            <!-- ロット -->
            <template v-slot:[`header.lot`]="{ header }">
              <span>
                {{ header.text }}<br />
                {{
                  $t("label.lbl_dueDate") +
                  "／" +
                  $t("label.lbl_lotSubkey1") +
                  "／" +
                  $t("label.lbl_lotSubkey2")
                }}
              </span>
            </template>

            <!-- 出荷No -->
            <template v-slot:[`item.outListNo`]="{ item }">
              <div class="listNo-style">{{ item.outListNo }}</div>
            </template>
            <template v-slot:[`item.wareLoca`]="{ item }">
              <!-- 倉庫・ロケ -->
              <div>{{ item.warehouseName }}</div>
              <div>{{ item.locationNo }}</div>
            </template>
            <!--品番/品名-->
            <template v-slot:[`item.itemCd`]="{ item }">
              <div v-if="item.itemCd.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-truncate"
                      style="max-width: 250px; text-align: left"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.itemCd }}<br />
                      {{ item.accessoryProductCd }}<br />
                      {{ item.itemName }}
                    </div>
                  </template>
                  <span
                    >{{ item.itemCd }}<br />
                    {{ item.accessoryProductCd }}<br />
                    {{ item.itemName }}
                  </span>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.itemCd }}<br />
                {{ item.accessoryProductCd }}<br />
                {{ item.itemName }}
              </div>
            </template>
            <!--ロット/S/N-->
            <template v-slot:[`item.lot`]="{ item }">
              <div v-if="item.lot.length > 15 || item.dueDate.length > 15">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="text-truncate"
                      style="max-width: 250px; text-align: left"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ item.lot }}<br />
                      {{ item.dueDate }}
                    </div>
                  </template>
                  <span>
                    {{ item.lot }}<br />
                    {{ item.dueDate }}
                  </span>
                </v-tooltip>
              </div>
              <div v-else>
                {{ item.lot }}<br />
                {{ item.dueDate }}
              </div>
            </template>
            <template v-slot:[`item.caseSu`]="{ item, index }">
              <div
                class="table-actualQuantity"
                v-if="item.deleteFlg == '1' || item.dstSlipFlg == '1'"
              >
                <!-- ケース数 -->
                {{ item.caseSu }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.caseSu"
                  class="input-number"
                  @click="clickPop(item.caseSu)"
                  @change="changeCase(item, index)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'caseSuCheckisNumber' + index"
                />
              </div>
            </template>

            <template v-slot:[`item.ballSu`]="{ item, index }">
              <div
                class="table-actualQuantity"
                v-if="item.deleteFlg == '1' || item.dstSlipFlg == '1'"
              >
                <!-- ポール数-->
                {{ item.ballSu }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.ballSu"
                  @click="clickPop(item.ballSu)"
                  class="input-number"
                  @change="changeBall(item, index)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'ballSuCheck' + index"
                />
              </div>
            </template>

            <template v-slot:[`item.baraSu`]="{ item, index }">
              <div
                class="table-actualQuantity"
                v-if="item.deleteFlg == '1' || item.dstSlipFlg == '1'"
              >
                <!-- バラ数-->
                {{ item.baraSu }}
              </div>
              <div v-else>
                <v-text-field
                  outlined
                  dense
                  v-model="item.baraSu"
                  @click="clickPop(item.baraSu)"
                  class="input-number"
                  @change="changeBara(item, index)"
                  persistent-hint
                  :rules="[
                    rules.isNumber,
                    rules.isInteger,
                    rules.isRangeCheckBara,
                    rules.limitNumLength,
                  ]"
                  clear-icon="mdi-close-circle"
                  clearable
                  :ref="'baraSuCheck' + index"
                />
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-form>

      <!-- 戻るボタンのダイアログ -->
      <v-dialog v-model="updateDialogMessage" :max-width="800">
        <v-card>
          <v-card-title class="blue-grey lighten-3" primary-title>
            {{ $t("btn.btn_ok") }}
          </v-card-title>
          <v-card-text class="pa-4">
            <p>{{ backMessage }}</p>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="updateOk"> OK </v-btn>
            <v-btn v-if="errorFlg" @click="updateNg"> {{ $t("btn.btn_cancel") }} </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-navigation-drawer absolute temporary v-model="openMenu">
        <sideMenu></sideMenu>
      </v-navigation-drawer>
      <v-dialog v-model="simpleDialog" persistent="" no-click-animation>
        <SimpleDialog
          :isShow.sync="simpleDialog"
          :title="infoDialog.title"
          :message="infoDialog.message"
          :homePageFlag="infoDialog.homePageFlag"
          :resConFlg="infoDialog.resConFlg"
          :firstPageFlag="infoDialog.firstPageFlag"
        />
      </v-dialog>
    </v-sheet>
  </div>
</template>

<script>
import Loading from "@/components/loading";
import ConfirmDialog from "@/components/ConfirmDialog";
import SimpleDialog from "@/components/SimpleDialog";
import NavBar from "../../components/NavBar.vue";
import sideMenu from "@/components/Menu";
import { appConfig } from "../../assets/scripts/js/AppConfig";
// import { controlNumberUtil } from "../../assets/scripts/js/ControlNumberUtil";
import { dateTimeHelper } from "../../assets/scripts/js/DateTimeHelper";
import { commonUtil } from "../../assets/scripts/js/CommonUtil";
import { messsageUtil } from "../../assets/scripts/js/MesssageUtil";
// import { getParameter } from "../../assets/scripts/js/GetParameter";
//import { functionAuth } from "../assets/scripts/js/FunctionAuth";
//import { screenAuth } from "../assets/scripts/js/ScreenAuth";
import { i18n } from "../../lang/lang.js";

export default {
  name: appConfig.SCREEN_ID.P_EXT_001,
  components: {
    Loading,
    SimpleDialog,
    sideMenu,
    ConfirmDialog,
    NavBar,
  },
  props: {
    isOpenDetailDialog: { type: Boolean, default: false },
    isMenu: { type: Boolean, default: false },
  },
  data: () => ({
    loadingCounter: 0,
    // バリデーションチェックリスト
    editedList: [],
    // 取引先
    suppliersSelected: "",
    // 出荷先
    shippingSelected: "",
    // 出荷日
    shippingScheduleDate: "",
    // ピッキング
    picking: "",
    // ピッキングNo
    pickingNo: "",
    // 責任
    isRes: "",
    blameDivList: [],
    // 有償無償
    isPaid: "",
    freeCostDivList: [],
    // 無償理由
    freeReasonSelected: "",
    // 処理区分
    processDivSelected: "",
    // メニュー
    openMenu: null,
    // メッセージダイアログ
    dialogMessage: false,
    // 入力チェックエラーフラグ
    errorFlg: true,
    // 戻るボタンダイアログ
    updateDialogMessage: false,
    // 戻るボタンダイアログメッセージ
    backMessage: "",
    // 一覧index
    editedIndex: -1,
    // 確認メッセージ
    ConfirmDialog: {
      message: "",
      redMessage: "",
      isOpen: false,
    },
    simpleDialog: false,
    // メッセージダイアログ
    infoDialog: {
      title: "",
      message: "",
      firstPageFlag: false,
      resConFlg: false,
      homePageFlag: false,
    },
    productListIdx: -1,
    // 出荷予定品目一覧ヘッダ
    headerItems: [
      {
        // 出荷No
        text: i18n.tc("label.lbl_shippingNo"),
        value: "outListNo",
        width: "8%",
        sortable: false,
        align: "center",
      },
      {
        // 担当者
        text: i18n.tc("label.lbl_popDestinationNm"),
        value: "userName",
        width: "6%",
        align: "center",
        sortable: false,
      },
      {
        // 倉庫/ロケ
        text: i18n.tc("label.lbl_warehouse") + "/" + i18n.tc("label.lbl_location"),
        value: "wareLoca",
        width: "10%",
        align: "left",
        sortable: false,
      },
      {
        // 品番
        text: i18n.tc("label.lbl_productCnCd"),
        value: "itemCd",
        width: "15%",
        align: "left",
        sortable: false,
      },
      // ロット
      {
        text: i18n.tc("label.lbl_lot") + "／" + i18n.tc("label.lbl_serial"),
        value: "lot",
        width: "17%",
        align: "left",
      },
      {
        // 指示数量
        text: i18n.tc("label.lbl_indicatedQuantity"),
        value: "productQty",
        width: "6%",
        align: "right",
      },
      {
        // 差
        text: i18n.tc("label.lbl_difference"),
        value: "difference",
        width: "4%",
        align: "right",
      },
      {
        // 実績数量
        text: i18n.tc("label.lbl_achieveQuantity"),
        value: "acheieveQty",
        width: "6%",
        align: "right",
      },
      {
        // 数量
        text: i18n.tc("label.lbl_quantity"),
        value: "ballSu",
        width: "8%",
        align: "center",
      },
      {
        // 満数荷姿
        text: i18n.tc("label.lbl_fullPackage"),
        value: "caseSu",
        width: "8%",
        align: "center",
      },
      {
        // 端数
        text: i18n.tc("label.lbl_fraction"),
        value: "baraSu",
        width: "8%",
        align: "center",
      },
    ],
    inputList: [],
    //予定一覧のデータ
    arrivalDataList: [],
    //検索に使う予定一覧のデータ
    arrivalInputlDataList: [],
    rules: {
      isNumber: (value) =>
        (!!value && !isNaN(Number(commonUtil.zen2han(value).replaceAll(",", "")))) ||
        i18n.tc("check.chk_inputNumber"),
      isInteger: (value) =>
        Number.isInteger(Number(commonUtil.zen2han(value).replaceAll(",", ""))) ||
        i18n.tc("check.chk_inputNotDecimal"),
      isRangeCheckBara: (value) =>
        0 <= Number(commonUtil.zen2han(value).replaceAll(",", "")) ||
        i18n.tc("check.chk_inputMoreOne"),
      limitNumLength: (value) =>
        value == null ||
        String(value).replaceAll(",", "").length < 10 ||
        i18n.tc("check.chk_limitNumLength"), // 文字数の制約
    },
  }),

  created() {
    // 実績登録画面で必要な値
    // 出荷伝票SID
    this.shipSid = this.$route.params.shipSid;
    // 出荷先名（出荷先名（出荷先コード））
    this.shippingSelected = this.$route.params.shippingSelected;
    // 出荷日
    this.shippingScheduleDate = this.$route.params.shippingScheduleDate;
    // ピッキング区分
    this.picking = this.$route.params.picking;
    // ピッキングNo
    this.pickingNo = this.$route.params.pickingNo;
    // ピッキング名
    this.pickingNm = this.$route.params.pickingNm;
    // 取引先SID
    this.clientSid = this.$route.params.searchSuppliersSelected;
    // 出荷先SID
    this.toSid = this.$route.params.toSid;
    // 取引先名
    this.suppliersSelected = this.$route.params.clientNm;

    // 検索条件
    // 取引先
    this.searchSuppliersSelected = this.$route.params.searchSuppliersSelected;
    // 出荷先
    this.searchShippingSelected = this.$route.params.searchShippingSelected;
    // 出荷日From
    this.shippingScheduleDateFrom = this.$route.params.shippingScheduleDateFrom;
    // 出荷日FromCal
    this.shippingScheduleDateFromCal = this.$route.params.shippingScheduleDateFromCal;
    // 出荷日TO
    this.shippingScheduleDateTo = this.$route.params.shippingScheduleDateTo;
    // 出荷日ToCal
    this.shippingScheduleDateToCal = this.$route.params.shippingScheduleDateToCal;
    // ステータス
    this.statusSelected = this.$route.params.statusSelected;
    // ピッキング
    this.pickingSelected = this.$route.params.pickingSelected;

    // 出庫実績取得
    this.getDetail();
  },

  methods: {
    /**
     * 初期起動：画面を開いた際に渡された値を設定(出荷予定一覧から値受け取る)
     */
    init() {
      // ceatedにて記載
    },

    /**
     * メニューダイアログ処理
     */
    showMenu() {
      this.openMenu = !this.openMenu;
    },

    /**
     * 出荷実績数、差計算
     */
    changeQty(val, index) {
      // 荷姿、数値変換
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      // 数量、数値変換
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      // 端数、数値変換
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      if (!isNaN(Number(caseSu)) || Number(ballSu) || Number(baraSu)) {
        // 出荷実績数の計算（数量 × 荷姿 ＋ 端数）
        var calculation = Number(ballSu) * Number(caseSu) + Number(baraSu);
        var calculationAfter = Number(this.inputList[index].oriAcheieveQty) + Number(calculation);
        this.inputList[index].acheieveQty = commonUtil.formatToCurrency(Number(calculationAfter));

        // 出荷実績数、数値変換
        var atualQuantity = commonUtil
          .zen2han(this.inputList[index].acheieveQty)
          .replaceAll(",", "");
        // 出荷予定数、数値変換
        var inScheduleQuantity = commonUtil
          .zen2han(this.inputList[index].productQty)
          .replaceAll(",", "");
        // 差の計算（出荷実績数-出荷予定数）
        var subtraction = atualQuantity - inScheduleQuantity;
        if (Math.sign(subtraction) == 1) {
          this.inputList[index].difference = "+" + commonUtil.formatToCurrency(Number(subtraction));
        } else if (Math.sign(subtraction) == 0) {
          this.inputList[index].difference = commonUtil.formatToCurrency(Number(subtraction));
        } else {
          this.inputList[index].difference = commonUtil.formatToCurrency(Number(subtraction));
        }
      }
    },

    /**
     * 戻るボタン処理
     */
    backbtn() {
      this.updateDialogMessage = true;
      this.backMessage = messsageUtil.getMessage("P-RCV-004_003_W");
    },

    /**
     * 一覧画面へ渡す値を設定
     */
    updateOk() {
      if (this.errorFlg) {
        this.updateDialogMessage = false;
        this.$router.push({
          name: appConfig.MENU_ID.P_STK_IVEN_ALLOC_DEL,
          params: {
            // 検索条件
            // 取引先
            searchSuppliersSelected: this.searchSuppliersSelected,
            // 出荷先
            searchShippingSelected: this.searchShippingSelected,
            // 出荷日From
            shippingScheduleDateFrom: this.shippingScheduleDateFrom,
            // 出荷日FromCal
            shippingScheduleDateFromCal: this.shippingScheduleDateFromCal,
            // 出荷日To
            shippingScheduleDateTo: this.shippingScheduleDateTo,
            // 出荷日ToCal
            shippingScheduleDateToCal: this.shippingScheduleDateToCal,
            // ステータス
            statusSelected: this.statusSelected,
            // ピッキング
            pickingSelected: this.pickingSelected,
            // 出荷先一覧
            shippingList: this.$route.params.shippingList,
            // 出荷先名表示用
            shippingNm: this.$route.params.shippingSelected,
            //納品日
            dateToDelivery: this.$route.params.dateToDelivery,
            dateToDeliveryCal: this.$route.params.dateToDeliveryCal,
          },
        });
      } else {
        this.updateDialogMessage = false;
        this.errorFlg = true;
      }
    },

    /**
     * 戻るボタンダイアログ画面：NG処理
     */
    updateNg() {
      this.updateDialogMessage = false;
    },

    /**
     * 入力カンマ処理 数量
     */
    changeCase(val, index) {
      var caseSu = commonUtil.zen2han(val.caseSu).replaceAll(",", "");
      caseSu = caseSu.replace(/[^0-9]/gi, "");
      if (caseSu.length > 9) {
        caseSu = caseSu.substring(0, 9);
      }
      if (!isNaN(Number(caseSu))) {
        this.inputList[index].caseSu = commonUtil.formatToCurrency(Number(caseSu));
      }
      // 数値変換
      this.changeQty(val, index);
    },
    clickPop(val) {
      let myName = event.target;
      let pop = val;
      if (val != null && val.length > 3) {
        pop = val.replaceAll(",", "");
      }
      myName.value = pop;
    },

    /**
     * 入力カンマ処理 満数荷姿
     */
    changeBall(val, index) {
      var ballSu = commonUtil.zen2han(val.ballSu).replaceAll(",", "");
      ballSu = ballSu.replace(/[^0-9]/gi, "");
      if (ballSu.length > 9) {
        ballSu = ballSu.substring(0, 9);
      }
      if (!isNaN(Number(ballSu))) {
        this.inputList[index].ballSu = commonUtil.formatToCurrency(Number(ballSu));
      }
      // 数値変換
      this.changeQty(val, index);
    },

    /**
     * 入力カンマ処理 端数
     */
    changeBara(val, index) {
      var baraSu = commonUtil.zen2han(val.baraSu).replaceAll(",", "");
      baraSu = baraSu.replace(/[^0-9]/gi, "");
      if (baraSu.length > 9) {
        baraSu = baraSu.substring(0, 9);
      }
      if (!isNaN(Number(baraSu))) {
        this.inputList[index].baraSu = commonUtil.formatToCurrency(Number(baraSu));
      }
      // 数値変換
      this.changeQty(val, index);
    },

    /**
     * 登録ボタン押下
     */
    rcvAchAdd() {
      let cnt = 0;
      let inputCnt = 0;
      let cntChenge = [];
      if (this.$refs.editedList.validate()) {
        // 明細部、入力チェック実施
        for (var i = 0; i < this.inputList.length; i++) {
          var checkCaseNum = Number(
            commonUtil.zen2han(this.inputList[i].caseSu).replaceAll(",", "")
          );
          var checkBaraNum = Number(
            commonUtil.zen2han(this.inputList[i].baraSu).replaceAll(",", "")
          );
          var checkBallNum = Number(
            commonUtil.zen2han(this.inputList[i].ballSu).replaceAll(",", "")
          );

          // 数値チェック
          if (isNaN(checkBallNum) || isNaN(checkCaseNum) || isNaN(checkBaraNum)) {
            // 入荷予定数、ボール数、ケース数、バラ数が数値でない時
            cnt = i + 1;
          } else {
            // 処理なし
          }

          // 整数チェック
          if (
            Number.isInteger(checkBallNum) &&
            Number.isInteger(checkCaseNum) &&
            Number.isInteger(checkBaraNum)
          ) {
            // 処理なし
          } else {
            // 入荷予定数、ボール数、ケース数、バラ数が整数でない時
            cnt = i + 1;
          }

          if (
            this.inputList[i].oriCaseSu == this.inputList[i].caseSu &&
            this.inputList[i].oriBallSu == this.inputList[i].ballSu &&
            this.inputList[i].oriBaraSu == this.inputList[i].baraSu
          ) {
            // 修正していないレコードを探し、レコード数を数える
            cntChenge.push(this.inputList[i]);
          } else if (
            (this.inputList[i].caseSu == 0 && this.inputList[i].ballSu > 0) ||
            (this.inputList[i].ballSu == 0 && this.inputList[i].caseSu > 0)
          ) {
            // 数量が0/満数荷姿１が以上 or 数量が1以上 のレコーダー数を数える
            inputCnt = i + 1;
          }
        }
        // 明細部、入力エラーなし（カウント0）かつ、修正していないレコードがinputListのレコード数と一致しない場合、確認メッセージ表示
        if (cnt == 0 && cntChenge.length != this.inputList.length && inputCnt == 0) {
          this.addFlg = true;
          this.ConfirmDialog.message = messsageUtil.getMessage("P-RCV-004_002_C");
          this.ConfirmDialog.isOpen = true;
          this.ConfirmDialog.screenFlag = true;
          this.ConfirmDialog.changeFlag = false;
        } else {
          if (cntChenge.length == this.inputList.length) {
            // 修正点がなかった時
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-006_001_E");
          } else if (inputCnt > 0) {
            // 入力方法が誤っていた場合
            this.errorFlg = false;
            this.updateDialogMessage = true;
            this.backMessage = messsageUtil.getMessage("P-RCV-006_002_E");
          } else if (this.$refs.editedList.validate()) {
            // 明細部、入力エラー時（カウント1）
            // 処理なし
          }
        }
      } else {
        // 処理なし
      }
    },

    /**
     * 検索処理（出庫実績GETAPI）
     */
    getDetail() {
      // ローディング画面表示ON
      this.loadingCounter = 1;

      const config = this.$httpClient.createGetApiRequestConfig();

      // 取引先SID
      config.params.clientSid = this.clientSid;
      // toSid
      config.params.toSid = this.toSid;
      // 出荷予定日
      config.params.outScheduleDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);
      // ピッキング区分
      config.params.pickingDiv = this.picking;
      // ピッキングNo
      config.params.pickingGrSid = this.pickingNo;

      // 基準日対応
      config.params.reqComReferenceDate = dateTimeHelper.convertUTC(this.shippingScheduleDate);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .secureGet(appConfig.API_URL.BIZ_OUTWAREACTUALS, config)
          .then((response) => {
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // console.debug("getDetail() Response", response);

            const apiData = jsonData.resIdv.outWareActuals;

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              for (var j = 0; j < apiData.length; j++) {
                // API結果：出荷予定一覧明細
                let apiDataItem = apiData[j];
                let lotObj = commonUtil.convertLotNo(apiDataItem.lotNo);
                // API結果保持変数
                let inputListItem = {};
                // 出荷伝票明細SID
                inputListItem.outListSubSid = apiDataItem.outListSubSid;
                // 出荷伝票No
                inputListItem.outListNo = apiDataItem.outListNo;
                // 品番
                inputListItem.itemCd = apiDataItem.itemCd.substring(0, 50).trim();
                inputListItem.itemCdShow = apiDataItem.itemCd;
                //付帯品番
                inputListItem.accessoryProductCd = apiDataItem.itemCd.slice(-50).trim();
                // 品名
                inputListItem.itemName = apiDataItem.itemName;
                // ロット、S/N
                inputListItem.lot = lotObj.lot;
                inputListItem.lotShow = apiDataItem.lotNo;
                // 期限日、付属キー１、付属キー２
                inputListItem.dueDate = lotObj.dueDate;
                // 指示数量
                let productQtyNum = apiDataItem.signStock;
                inputListItem.productQty = String(
                  commonUtil.formatToCurrency(Number(productQtyNum))
                );
                // 差
                inputListItem.difference = apiDataItem.diff;
                inputListItem.oriDifference = apiDataItem.diff;
                if (apiDataItem.deleteFlg == "0") {
                  // 出荷実績数
                  let acheieveQty = apiDataItem.stock;
                  inputListItem.acheieveQty = String(
                    commonUtil.formatToCurrency(Number(acheieveQty))
                  );
                  inputListItem.oriAcheieveQty = String(
                    commonUtil.formatToCurrency(Number(acheieveQty))
                  );
                } else if (apiDataItem.deleteFlg == "1") {
                  // 出荷実績数(指示数量を表示)
                  inputListItem.acheieveQty = String(
                    commonUtil.formatToCurrency(Number(productQtyNum))
                  );
                  inputListItem.oriAcheieveQty = String(
                    commonUtil.formatToCurrency(Number(productQtyNum))
                  );
                }
                // 荷姿（ケース数）
                let caseNum = "0";
                inputListItem.caseSu = String(commonUtil.formatToCurrency(Number(caseNum)));
                inputListItem.oriCaseSu = String(commonUtil.formatToCurrency(Number(caseNum)));
                if (inputListItem.productQty == inputListItem.acheieveQty) {
                  // 出荷予定数と出荷実績数が等しい時
                  inputListItem.caseSu = "";
                  inputListItem.oriCaseSu = "";
                }
                // 数量（ボール数）
                let ballNum = "0";
                inputListItem.ballSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                inputListItem.oriBallSu = String(commonUtil.formatToCurrency(Number(ballNum)));
                if (inputListItem.productQty == inputListItem.acheieveQty) {
                  // 出荷予定数と出荷実績数が等しい時
                  inputListItem.ballSu = "";
                  inputListItem.oriBallSu = "";
                }
                // 端数（バラ数）
                let baraNum = "0";
                inputListItem.baraSu = String(commonUtil.formatToCurrency(Number(baraNum)));
                inputListItem.oriBaraSu = String(commonUtil.formatToCurrency(Number(baraNum)));
                if (inputListItem.productQty == inputListItem.acheieveQty) {
                  // 出荷予定数と出荷実績数が等しい時
                  inputListItem.baraSu = "";
                  inputListItem.oriBaraSu = "";
                }
                // 商品標準SID
                inputListItem.itemStandardSid = apiDataItem.itemStandardSid;
                // 予定更新日時
                inputListItem.outListUpdateDatetime = apiDataItem.outListUpdateDatetime;
                // 有償無償区分
                inputListItem.freeCostDiv = apiDataItem.freeCostDiv;
                // 品質区分
                inputListItem.qualityDiv = apiDataItem.qualityDiv;
                // 責任区分
                inputListItem.blameDiv = apiDataItem.blameDiv;
                // ロケーションNo
                inputListItem.locationNo = apiDataItem.locationNo;
                // 倉庫名
                inputListItem.warehouseName = apiDataItem.warehouseName;
                // ユーザー名
                inputListItem.userName = apiDataItem.userName;
                // ロケSID
                inputListItem.locationSid = apiDataItem.locationSid;
                // 出庫指示SID
                inputListItem.wareOutInstSid = apiDataItem.wareOutInstSid;
                // 明細更新日時
                inputListItem.outListDtlUpdateDatetime = apiDataItem.outListDtlUpdateDatetime;
                // 管理No
                inputListItem.itemManageNo = apiDataItem.itemManageNo;
                // 削除フラグ
                inputListItem.deleteFlg = apiDataItem.deleteFlg;
                // 出荷実績数、数値変換
                var atualQuantity = commonUtil
                  .zen2han(inputListItem.acheieveQty)
                  .replaceAll(",", "");
                // 出荷予定数、数値変換
                var inScheduleQuantity = commonUtil
                  .zen2han(inputListItem.productQty)
                  .replaceAll(",", "");
                // 差の計算（出荷実績数-出荷予定数）
                var subtraction = atualQuantity - inScheduleQuantity;
                if (Math.sign(subtraction) == 1) {
                  inputListItem.difference = "+" + commonUtil.formatToCurrency(Number(subtraction));
                } else if (Math.sign(subtraction) == 0) {
                  inputListItem.difference = commonUtil.formatToCurrency(Number(subtraction));
                } else {
                  inputListItem.difference = commonUtil.formatToCurrency(Number(subtraction));
                }
                // 流通加工区分
                inputListItem.distDiv = apiDataItem.distDiv;
                // 前工程コード
                inputListItem.aheadProcCd = apiDataItem.aheadProcCd;
                // 次工程コード
                inputListItem.nextProcCd = apiDataItem.nextProcCd;
                // 流通加工明細フラグ
                inputListItem.dstSlipFlg = apiDataItem.dstSlipFlg;

                // inputListに格納
                this.inputList.push(inputListItem);
              }
              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },

    /**
     * 実績登録POSTAPI接続
     */
    deleteStock() {
      // ローディング画面表示ON
      this.loadingCounter = 1;
      const body = this.$httpClient.createRequestBodyConfig();

      //  画面ID
      body.reqCom.reqComComponentId = appConfig.SCREEN_ID.P_RCV_006;

      // 流通加工明細に対しては、出庫実績は登録しないようにする
      const stockDeleteTargetList = this.inputList.filter((v) => v.dstSlipFlg != "1");

      // 修正ﾘｽﾄ抽出
      var bodyList = [];
      for (var i = 0; i < stockDeleteTargetList.length; i++) {
        // 満数荷姿数分ループを回し、数量を設定。
        for (var j = 0; j < stockDeleteTargetList[i].caseSu; j++) {
          var inputMap = {};
          // 操作画面名
          inputMap.opeDispName = i18n.tc("label.lbl_leavingAchieveAddpc");
          // 工程CD（出庫:400）
          inputMap.procCd = "400";
          // 入出区分（入:01）
          inputMap.inOutDiv = "01";
          // モバイル区分
          inputMap.mobileDiv = "M";
          // 日時
          // let today = new Date();
          // today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          var nowDate = dateTimeHelper.convertUTC();
          inputMap.yyyymmddHhmmss = nowDate;
          // 商品標準SID
          inputMap.itemStandardSid = stockDeleteTargetList[i].itemStandardSid;
          // 品番
          inputMap.itemCd = stockDeleteTargetList[i].itemCdShow;
          // 管理No
          inputMap.itemManageNo = stockDeleteTargetList[i].itemManageNo;
          // ロット
          inputMap.lotNo = stockDeleteTargetList[i].lotShow;
          // 修正数量
          inputMap.stock = stockDeleteTargetList[i].ballSu.replaceAll(",", "");
          // ToSID（出荷先SID）
          inputMap.toSid = this.toSid;
          // FromSID（営業所SID）
          inputMap.fromSid = sessionStorage.getItem("sales_office_sid");
          // 有償無償区分
          inputMap.freeCostDiv = stockDeleteTargetList[i].freeCostDiv;
          // 品質区分
          inputMap.qualityDiv = stockDeleteTargetList[i].qualityDiv;
          // 責任区分
          inputMap.blameDiv = stockDeleteTargetList[i].blameDiv;
          // 出荷伝票明細SID
          inputMap.outListSubSid = stockDeleteTargetList[i].outListSubSid;
          // 営業所SID
          inputMap.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          inputMap.clientSid = this.clientSid;
          // 予定更新日時
          inputMap.scheduleUpdateDatetime = stockDeleteTargetList[i].outListUpdateDatetime;
          // 明細更新日時
          inputMap.detailUpdateDatetime = stockDeleteTargetList[i].outListDtlUpdateDatetime;
          // ロケSID
          inputMap.locationSid = stockDeleteTargetList[i].locationSid;
          // 出庫指示SID
          inputMap.wareOutInstSid = stockDeleteTargetList[i].wareOutInstSid;
          // 流通加工区分
          inputMap.distDiv = stockDeleteTargetList[i].distDiv;
          // 前工程コード
          inputMap.aheadProcCd = stockDeleteTargetList[i].aheadProcCd;
          // 次工程コード
          inputMap.afterProcCd = stockDeleteTargetList[i].nextProcCd;

          bodyList.push(inputMap);
        }

        // 端数がある場合、修正数量に端数設定する。
        if (stockDeleteTargetList[i].baraSu != 0) {
          var inputMapBara = {};
          // 操作画面名
          inputMapBara.opeDispName = i18n.tc("label.lbl_leavingAchieveAddpc");
          // 工程CD（出庫:400）
          inputMapBara.procCd = "400";
          // 入出区分（入:01）
          inputMapBara.inOutDiv = "01";
          // ロット
          inputMapBara.lotNo = stockDeleteTargetList[i].lotShow;
          // モバイル区分
          inputMapBara.mobileDiv = "M";
          // 日時
          // let today = new Date();
          // today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
          var nowDateBara = dateTimeHelper.convertUTC();
          inputMapBara.yyyymmddHhmmss = nowDateBara;
          // 商品標準SID
          inputMapBara.itemStandardSid = stockDeleteTargetList[i].itemStandardSid;
          // 品番
          inputMapBara.itemCd = stockDeleteTargetList[i].itemCdShow;
          // 管理No
          inputMapBara.itemManageNo = stockDeleteTargetList[i].itemManageNo;
          // 修正数量(端数)
          inputMapBara.stock = stockDeleteTargetList[i].baraSu.replaceAll(",", "");
          // ToSID（出荷先SID）
          inputMapBara.toSid = this.toSid;
          // FromSID（営業所SID）
          inputMapBara.fromSid = sessionStorage.getItem("sales_office_sid");
          // 有償無償区分
          inputMapBara.freeCostDiv = stockDeleteTargetList[i].freeCostDiv;
          // 品質区分
          inputMapBara.qualityDiv = stockDeleteTargetList[i].qualityDiv;
          // 責任区分
          inputMapBara.blameDiv = stockDeleteTargetList[i].blameDiv;
          // 出荷伝票明細SID
          inputMapBara.outListSubSid = stockDeleteTargetList[i].outListSubSid;
          // 営業所SID
          inputMapBara.officeSid = sessionStorage.getItem("sales_office_sid");
          // 取引先SID
          inputMapBara.clientSid = this.clientSid;
          // 予定更新日時
          inputMapBara.scheduleUpdateDatetime = stockDeleteTargetList[i].outListUpdateDatetime;
          // 明細更新日時
          inputMapBara.detailUpdateDatetime = stockDeleteTargetList[i].outListDtlUpdateDatetime;
          // ロケSID
          inputMapBara.locationSid = stockDeleteTargetList[i].locationSid;
          // 出庫指示SID
          inputMapBara.wareOutInstSid = stockDeleteTargetList[i].wareOutInstSid;

          bodyList.push(inputMapBara);
        }
      }

      // 実績一覧に追加
      body.reqIdv.actuals = bodyList;
      // console.debug("deleteStock() Body", body);

      return new Promise((resolve, reject) => {
        this.$httpClient
          // 接続先のAPI_URLを入力
          .securePost(appConfig.API_URL.BIZ_ACTUALS, body, appConfig.APP_CONFIG)
          .then((response) => {
            // console.debug("deleteStock() Response", response);
            const jsonData = JSON.parse(JSON.stringify(response.data));

            // 正常時
            if (jsonData.resCom.resComCode == "000") {
              this.infoDialog.message = messsageUtil.getMessage("P-RCV-001_001_C");
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              // 遷移ボタン
              this.infoDialog.homePageFlag = true;
              this.infoDialog.resConFlg = true;
              this.infoDialog.firstPageFlag = false;

              resolve(response);
            } else {
              // エラー時
              this.infoDialog.message = jsonData.resCom.resComMessage;
              this.infoDialog.title = appConfig.DIALOG.title;
              this.simpleDialog = true;
              this.infoDialog.firstPageFlag = true;
              reject();
            }
          })
          .catch((ex) => {
            this.infoDialog.message = ex;
            this.infoDialog.title = appConfig.DIALOG.title;
            this.simpleDialog = true;
            this.infoDialog.firstPageFlag = true;
            reject();
          })
          .finally(() => {
            // ローディング画面表示OFF
            this.loadingCounter = 0;
          });
      });
    },
  },

  computed: {},
  watch: {
    // eslint-disable-next-line no-unused-vars
    isMenu: function (newValue, oldValue) {
      if (newValue) {
        this.init();
      }
    },
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "../../css/style.css";
</style>
<style lang="scss" scoped>
//画面優先順位

.txt-single ::v-deep {
  //padding: 25px 0 0 0;
  padding-right: 0;
  font-size: large;
  pointer-events: none;
}

//新製品登録時、色変更
.isD {
  background-color: $color-warning !important;
  //background-color: $color-error !important;
}
.padding-bottom {
  margin-bottom: 20px;
}
.menu-btn {
  margin: 0.1rem 0.2rem;
  // font-size: $menu-fontsize !important;
  font-weight: bold;
}
::v-deep #btn-logout {
  img {
    width: 20px;
    height: 20px;
  }
}
#lbl-user-name {
  // font-size: $menu-fontsize !important;
  font-weight: bold;
  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("../../assets/img/user_menu_icon.png");
    background-size: contain;
    vertical-align: middle;
  }
}

#listData.v-data-table ::v-deep th {
  padding: 0 12px;
}
</style>
